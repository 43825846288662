import React, { useState } from 'react'
import {useNavigate} from "react-router-dom";
import {UserAuth} from "../Context/AuthContext";
import logoImage from "../best-embroidery-login.svg";
import {Image} from "react-bootstrap";

function Login() {


    const {signIn} = UserAuth();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const navigate = useNavigate()

    const handleSubmit = async (e) =>
    {

        e.preventDefault()
        setError("")

        try
        {

            await signIn(email,password)
            navigate("/logos")
        }
        catch (e)
        {

            setError(e.message)
            console.log(e.message)

        }

    }


    return(


        <div className={"h-screen  flex items-center justify-center"}>

        <div className={"max-w-md w-full p-6 rounded-lg"}>

            <div className="w-full flex justify-center h-full">
                <Image className={"py-16 xs:w-2/3 sm:w-4/6 md:w-9/12 lg:10/12"} src={logoImage}  alt="Logos" />
            </div>


            <form  onSubmit={handleSubmit}>

                <div className={"mb-3"}>

                    <input className={"flex-auto w-full text-center text-white h-10 mt-1 px-4 bg-card placeholder-background rounded-4xl border-medium border-radius-circle border-blackbg"} onChange={(e) => setEmail(e.target.value)} type={"email"}  required={true} placeholder={"Email Address"}/>

                </div>

                <div className={"mb-5"}>

                    <input className={"flex-auto w-full text-center text-white h-10 mt-1 px-4 bg-card  placeholder-background rounded-4xl border-medium border-radius-circle border-blackbg"} onChange={(e) => setPassword(e.target.value)} type={"password"} required={true} placeholder={"Password"}/>

                </div>


                <div className={"flex py-6 justify-center"}>

                    <button className={"rounded-4xl border-medium border-radius-circle border-blackbg bg-card text-white w-small hover:bg-blackbg"}>LOGIN</button>

                </div>


            </form>

            <p className="text-xs text-blackbg text-center mt-10">&copy; 2014 Best Embroidery Solutions</p>

        </div>

                </div>

        )

}

export default Login;
