import React, {useState} from "react";

// After the logo is created I will use the useNavigate hook
import {useNavigate} from "react-router-dom"
import axios from "axios";

function CreateLogo()
{

    const [logo_name, setLogoName] = useState("");
    const [logo_invoice_number, setInvoice] = useState("");
    const [logo_location, setLocation] = useState("");
    const [total_stitches, setStitches] = useState("");
    const [color_sequence, setSequence] = useState("");
    const [isPending, setIsPending] = useState(false);


    // creating the const that will use the navigate
    const navigate = useNavigate();

    // When hitting the submit button the fields will not go back to ""
    async function handleSubmit (e)
    {
        e.preventDefault();

        const formData = new FormData();

        formData.append("logo_name", logo_name);
        formData.append("logo_invoice_number", logo_invoice_number);
        formData.append("logo_location", logo_location);
        formData.append("color_sequence", color_sequence);
        formData.append("total_stitches", total_stitches);

        // formData.append("color_numbers", JSON.stringify(colorNumbers)); // Convert to JSON string
        // formData.append("color_comments", JSON.stringify(colorComments)); // Convert to JSON string

        setIsPending(true);

        try
        {

            await axios.post("https://embroidery-app-2sbi.onrender.com/logos/create", formData, {
                headers: {
                    'Content-Type': 'application/json', // Set the Content-Type to application/json
                },
            });

            console.log('New logo added', logo_name);
            navigate("/logos");

        }

        catch (error)
        {

            console.error('Error adding logo:', error);

        }

        finally
        {

            setIsPending(false);

        }

    }

    return(


        <div className="h-auto py-28 bg-gray-100 flex items-center justify-center">

            <div className="container items-center justify-center max-w-screen-lg mx-auto">

                <form  onSubmit={handleSubmit}>
                    <div>
                        <h1 className={"text-center xs:text-xl sm:text-extraxl lg:text-extraxl"}>Create Logo</h1>
                        <div className="p-4 px-4 md:p-8">

                            <div className="grid text-sm py-10 md:grid-cols-1 lg:grid-cols-1">

                                <div className="lg:col-span-2">


                                    <div className="grid gap-y-2 text-sm grid-cols-1 md:grid-cols-2">

                                        <div className="md:col-span-5 flex flex-wrap justify-between">
                                            <label className={"w-2/5 py-3"}>Logo Name</label>
                                            <input className="text-center text-white w-3/6 h-10 mt-1 px-4 bg-card rounded-4xl border-medium border-radius-circle border-blackbg" type={'text'} required={true} value={logo_name} onChange={(e) => setLogoName(e.target.value)} />
                                        </div>

                                        <div className="md:col-span-5 flex flex-wrap justify-between">
                                            <label className={"w-2/5 py-3"}>Logo Invoice</label>
                                            <input className="text-center text-white w-3/6 h-10 mt-1 px-4 bg-card rounded-4xl border-medium border-radius-circle border-blackbg" type={'text'} required={true} value={logo_invoice_number} onChange={(e) => setInvoice(e.target.value)} />
                                        </div>

                                        <div className="md:col-span-5 flex flex-wrap justify-between">
                                            <label className={"w-2/5 py-3"}>Logo Location</label>

                                            <select className={"block appearance-auto text-white w-3/6 text-center h-10 px-4 rounded-4xl border-medium border-radius-circle border-blackbg bg-card"} required={true} value={logo_location} onChange={(e) => setLocation(e.target.value)}>
                                                <option>-- No Location --</option>
                                                <option value="Gorra">Gorra</option>
                                                <option value="Cuello">Cuello</option>
                                                <option value="Espalda">Espalda</option>
                                                <option value="Pecho Izquierdo">Pecho Izquierdo</option>
                                                <option value="Pecho Derecho">Pecho Derecho</option>
                                                <option value="Manga Izquierda">Manga Izquierda</option>
                                                <option value="Manga Derecha">Manga Derecha</option>
                                            </select>

                                        </div>

                                        <div className="md:col-span-5 lg:col-span-5 flex flex-wrap justify-between">
                                            <label className={"w-2/5 py-3"}>Total Stitches</label>
                                            <input className="text-center h-10 mt-1 px-4 w-3/6 text-white rounded-4xl border-medium border-radius-circle border-blackbg bg-card" required={true} type={"number"}  value={total_stitches} onChange={(e) => setStitches(e.target.value )}/>
                                        </div>


                                        <div className="md:col-span-5 flex flex-wrap justify-between">
                                            <label className={"w-2/5 py-3"}>Color Sequence</label>
                                            <textarea className="text-center resize-y w-3/6 rounded-md border-medium border-radius-circle border-blackbg text-white transition-all flex items-center mt-1 px-4 bg-card" required={true} value={color_sequence} onChange={(e) => setSequence(e.target.value)} rows="15" cols="30" />
                                        </div>

                                        <div className="md:col-span-5 text-right">

                                            <div className="inline-flex items-start py-8">

                                                { !isPending && <button className={"rounded-4xl border-medium border-radius-circle border-blackbg bg-card text-white w-small hover:bg-blackbg"} type={"submit"}>Add Logo</button>}
                                                { isPending && <button disabled={"yes"}>Adding Logo...</button>}

                                            </div>

                                        </div>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                
                </form>

        </div>
</div>


    )
}

export default CreateLogo;