import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

function LogoByID ()
{

    const { id } = useParams();
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const navigate = useNavigate()

    const [logo, setLogo] = useState({

        logo_name: "",
        logo_invoice_number: "",
        logo_location: "",
        color_sequence: "",
        total_stitches: ""
});

    useEffect(() => {
        const fetchLogo = async () =>
        {

            try
            {

                const response = await axios.get(`https://embroidery-app-2sbi.onrender.com/logos/${id}`);
                setLogo(response.data.logo);
                setIsPending(false);

            }
            catch (error)
            {

                setError(error.message);
                setIsPending(false);

            }

        };

        fetchLogo();

    }, [id]); // Include id as a dependency to re-fetch data when id changes


    const deleteLogo = async () =>
    {

        try
        {

            await axios.delete(`https://embroidery-app-2sbi.onrender.com/logos/${id}`, logo.id)



            alert('You have deleted a logo')
            console.log("Logo has been deleted")
            navigate("/logos")

        }

        catch (error)
        {

            console.error("Error fetching logos:", error);

        }
    };


    const updateLogo = async () =>
    {

        try
        {

            const formData = new FormData();
            formData.append("logo_name", logo.logo_name);
            formData.append("logo_invoice_number", logo.logo_invoice_number);
            formData.append("logo_location", logo.logo_location);
            formData.append("color_sequence", logo.color_sequence);
            formData.append("total_stitches", logo.total_stitches);


            console.log("Updating logo with data:", formData); // Add this line

            // Make a PUT or PATCH request to update the logo information
            await axios.put(`https://embroidery-app-2sbi.onrender.com/logos/${id}`, formData,{
                headers: {
                    // CABRON, SIEMPRE PON CONTENT-TYPE BIEN MMB
                    'Content-Type': 'application/json', // Set the Content-Type to application/json

                },
            });

            console.log("Logo has been updated");
            navigate("/logos");

        }

        catch (error)
        {

            console.error("Error updating logo:", error);

        }

    };


    return (

        <div className="h-auto py-28 bg-gray-100 flex items-center justify-center">
                {isPending && <div>Loading...</div>}
                 {error && <div>{error}</div>}
                 {logo && (
            <div className="container items-center justify-center max-w-screen-lg mx-auto">

                <form>
                    <div>
                        <h1 className={"text-center xs:text-xl sm:text-extraxl lg:text-extraxl"}>Logo Information for {logo.logo_name}</h1>
                        <div className="p-4 px-4 md:p-8">

                            <div className="grid text-sm py-10 md:grid-cols-1 lg:grid-cols-1">

                                <div className="lg:col-span-2">

                                    <div className="grid gap-y-2 text-sm grid-cols-1 md:grid-cols-2">

                                        <div className="md:col-span-5 flex flex-wrap justify-between">
                                            <label className={"w-2/5 py-3"}>Logo Name</label>
                                            <input className="text-center text-white w-3/6 h-10 mt-1 px-4 bg-card rounded-4xl border-medium border-radius-circle border-blackbg" type={'text'} required={true} name={"logo_name"} value={logo.logo_name} onChange={(e) => setLogo({ ...logo, logo_name: e.target.value })} />
                                        </div>

                                        <div className="md:col-span-5 flex flex-wrap justify-between">
                                            <label className={"w-2/5 py-3"}>Logo Invoice</label>
                                            <input className="text-center text-white w-3/6 h-10 mt-1 px-4 bg-card rounded-4xl border-medium border-radius-circle border-blackbg" type={'text'} required={true} name={"logo_invoice_number"} value={logo.logo_invoice_number} onChange={(e) => setLogo({ ...logo, logo_invoice_number: e.target.value })} />
                                        </div>

                                        <div className="md:col-span-5 flex flex-wrap justify-between">
                                            <label className={"w-2/5 py-3"}>Logo Location</label>

                                            <select
                                                className={"block appearance-auto text-white w-3/6 text-center h-10 px-4 rounded-4xl border-medium border-radius-circle border-blackbg bg-card"}
                                                required={true} value={logo.logo_location}
                                                onChange={(e) => setLogo({...logo, logo_location: e.target.value})}>
                                                <option>-- No Location --</option>
                                                <option value="Gorra">Gorra</option>
                                                <option value="Cuello">Cuello</option>
                                                <option value="Espalda">Espalda</option>
                                                <option value="Pecho Izquierdo">Pecho Izquierdo</option>
                                                <option value="Pecho Derecho">Pecho Derecho</option>
                                                <option value="Manga Izquierda">Manga Izquierda</option>
                                                <option value="Manga Derecha">Manga Derecha</option>
                                            </select>

                                        </div>

                                        <div className="md:col-span-5 lg:col-span-5 flex flex-wrap justify-between">
                                            <label className={"w-2/5 py-3"}>Total Stitches</label>
                                            <input className="text-center h-10 mt-1 px-4 w-3/6 text-white rounded-4xl border-medium border-radius-circle border-blackbg bg-card" required={true} type={"number"}  name={"total_stitches"} value={logo.total_stitches} onChange={(e) => setLogo({ ...logo, total_stitches: e.target.value })}/>
                                        </div>


                                        <div className="md:col-span-5 flex flex-wrap justify-between">
                                            <label className={"w-2/5 py-3"}>Color Sequence</label>
                                            <textarea className="text-center resize-y w-3/6 rounded-md border-medium border-radius-circle border-blackbg text-white transition-all flex items-center mt-1 px-4 bg-card" required={true} name={"color_sequence"} value={logo.color_sequence} onChange={(e) => setLogo({...logo, color_sequence: e.target.value})} rows="15" cols="30"/>
                                        </div>


                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                </form>

                <div className="md:col-span-5 text-right space-x-2">

                    <button className={"rounded-4xl border-medium border-radius-circle border-blackbg bg-card text-white w-small hover:bg-blackbg "} onClick={updateLogo}>Update</button>
                    <button className={"rounded-4xl border-medium border-radius-circle border-blackbg bg-card text-white w-small hover:bg-blackbg "} onClick={deleteLogo}>Delete</button>

                </div>

            </div>
                     )}

        </div>

    );
}

export default LogoByID;