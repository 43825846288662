import Navbar from "./components/Navbars";
import {Route, Routes} from "react-router-dom";
import LogoList from "./components/LogoList";
import LogoByID from "./components/LogoByID";
import Support from "./components/Support";
import CreateLogo from "./components/CreateLogo";
import NotFound from "./components/NotFound";
import Login from "./components/Auth/Login";
import SignUp from "./components/Auth/SignUp";
import Account from "./components/Auth/Account";
import {AuthContextProvider} from "./components/Context/AuthContext";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Navbars from "./components/Navbars";

function App() {


  return (

              <div className={"min-h-screen scroll-smooth  bg-background text-sans"}>

                  <AuthContextProvider>
                       <ProtectedRoutes> <Navbars/> </ProtectedRoutes>
                      <Routes>

                          <Route path={"/login"} element={<Login/>}/>

                          <Route path={"/logos"} element={<ProtectedRoutes> <LogoList/> </ProtectedRoutes> }/>
                          <Route path={"/logos/:id"} element={<ProtectedRoutes> <LogoByID/> </ProtectedRoutes>}/>
                          <Route path={"/logos/create"} element={<ProtectedRoutes> <CreateLogo/> </ProtectedRoutes>}/>
                          <Route path={"/account"} element={<ProtectedRoutes> <Account/> </ProtectedRoutes>}/>
                          <Route path={"/support"} element={<ProtectedRoutes> <Support/> </ProtectedRoutes>}/>
                          <Route path={"*"} element={<ProtectedRoutes> <NotFound/> </ProtectedRoutes>}/>

                      </Routes>

                  </AuthContextProvider>

              </div>

);
}

export default App;
