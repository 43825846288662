import React from 'react'
import {UserAuth} from "../Context/AuthContext";
import {useNavigate} from "react-router-dom";

function Account() {


    const {user, logout} = UserAuth()
    const navigate = useNavigate()

    const handleLogout = async () =>
    {

        try
         {

             await logout()
             navigate("/")

             console.log("You are logout")

         }

         catch (e)
         {

            console.log(e.message)

         }

    }

    return(

        <div className={"grid py-28 w-1/2 grid-cols-1 mx-auto space-y-12"}>
            <a className={"text-center text-extraxl"}>Account</a>

            <p>User Email: {user && user.email}</p>
            <button onClick={handleLogout} className={"rounded-4xl float-left border-medium border-radius-circle border-black bg-white w-small"}>Logout</button>
        </div>

    )
}

export default Account;
