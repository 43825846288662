import {Link} from "react-router-dom";
import {Image} from "react-bootstrap";
import logoImage from "./best-embroidery.svg";
import React, {useState} from "react";
import {UserAuth} from "./Context/AuthContext";
import {useNavigate} from "react-router-dom";
import NotFound from "./NotFound";

function Navbars({logo}) {

    const {user, logout} = UserAuth()
    const navigate = useNavigate()
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLogout = async () =>
    {

        try
        {

            await logout()
            navigate("/")

            console.log("You are logout")

        }

        catch (e)
        {

            console.log(e.message)

        }

    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (

        <div >

            <nav className="bg-login-background mt-px font-display text-5xl font-medium  mb-10 left-0 right-0 top-2 z-40 pt-0">

                <div className="mx-auto max-w-7xl  px-6 lg:px-8">

                    <div className="mx-2 flex flex-wrap items-center justify-between">

                        <Link to={"/logos"}><Image className={"py-2 xs:w-2/3 sm:w-4/6 md:w-9/12 lg:w-full"} src={logoImage}/></Link>

                        <div className="md:hidden text-center">

                            <button className="text-card hover:text-blackbg" onClick={toggleMenu}>
                                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24"><path fillRule="evenodd" d="M2 5a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zm0 8a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zm0 8a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1z"/></svg>

                            </button>

                        </div>


                        <div className={`md:flex flex  flex-grow justify-end text-card items-end w-full ${isMenuOpen ? "block" : "hidden"}  md:w-auto md:order-1`}>
                            <ul className="flex flex-col text-xl py-4 space-x-8 md:flex-row md:text-xl space-y-4 md:space-y-0 md:space-x-8">

                                <li>
                                    <Link to={"/logos"} className={"hover:text-blackbg hover:border-b-2 hover:h-10 hover:cursor-pointer"} onClick={toggleMenu}><a aria-current="page">Dashboard</a></Link>
                                </li>

                                <li>
                                    <Link to={"/logos/create"} className={"hover:text-blackbg hover:border-b-2 hover:cursor-pointer"} onClick={toggleMenu}><a>Create</a></Link>
                                </li>

                                <li>
                                    <Link to={"/account"} className={"hover:text-blackbg hover:border-b-2  hover:cursor-pointer"} onClick={toggleMenu}><button onClick={handleLogout}>Logout</button></Link>
                                </li>

                            </ul>


                        </div>


                    </div>


                </div>

            </nav>


        </div>
    );
}

export default Navbars;
