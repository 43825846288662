import React from "react";

function Support()
{

    return(

        <div className={"grid py-28 w-1/2 grid-cols-1 mx-auto space-y-12"}>

            <h1 className={"text-center text-extraxl"}>Contact</h1>
            <p className={"py-2"}>Problems with the application? <a className={"underline"} href={"mailto:lopez87@keemail.me"}>Send Email</a> </p>


        </div>
    )

}

export default Support