// No need to use the props for this one, just add the variables you want like below
import React, {useEffect, useState} from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";

function LogoList(count)
{

    const [logo, setLogo] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    // For the pagination
    const [currentPage, setCurrentPage] = useState(0);
    const [logosPerPage] = useState(20); // Adjust the number of logos per page
    const [totalPages, setTotalPages] = useState(1);
    const indexOfLastLogo = (currentPage + 1) * logosPerPage;
    const indexOfFirstLogo = indexOfLastLogo - logosPerPage;
    const currentLogos = (searchResults.length > 0 ? searchResults : logo).slice(indexOfFirstLogo,indexOfLastLogo);

    useEffect(() =>
    {
        const getLogos = async () =>
        {

            try
            {

                const res = await axios.get("https://embroidery-app-2sbi.onrender.com/logos");
                console.log(res.data.logo);
                setLogo(res.data.logo);
                setTotalPages(Math.ceil(res.data.logo.length / logosPerPage));

            }
            catch (error)
            {

                console.error("Error fetching logos:", error);

            }

        };

        getLogos();
    }, [logosPerPage]);

    const handleSearch = count =>
    {

        const results = logo.filter((logosDisplay) =>

            // if you put the complete number it returns the exact logo, iif you put the first 2 numbers it returns related logos
            // no matter the position of the numbers
            String(logosDisplay.logo_invoice_number).indexOf(searchTerm) !== -1

        );

        setSearchResults(results);

    };


    const changePage = ({ selected }) =>
    {

        setCurrentPage(selected);

    };



    return(


        <div>

            {/*Search bar in the logo */}
            <div className={'flex items-center py-6 w-64 text-background justify-center mx-auto'}>

                <div className={"flex rounded-full bg-card px-2 w-full  border-4 border-neutral-950 max-w-[600px]"}>

                    <button className={"self-center flex p-1 cursor-pointer bg-card"}>
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                    </button>

                    <input type="text" className={"w-full bg-card  placeholder-background flex pl-1 "} placeholder="Search Invoice" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>

                    <button onClick={handleSearch} className="relative text-background p-2 bg-card rounded-full">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="30px" height="30px">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"/>
                        </svg>

                    </button>

                </div>

            </div>

            {/*Pagination in the logo list page*/}
            <div className={"flex justify-center pt-20 my-4"}>
                <ReactPaginate  previousLabel={"Previous"} nextLabel={"Next"} pageCount={totalPages} onPageChange={changePage} containerClassName={"paginationBttns"} previousLinkClassName={"px-3 hover:text-card text-blackbg"} nextLinkClassName={"px-3 hover:text-card text-blackbg"} disabledClassName={"paginationDisabled"} activeClassName={"border-2 hover:border-card hover:text-white hover:bg-card rounded-full"} pageLinkClassName={"px-2"}/>
            </div>

            <div
                className={"grid justify-items-center w-6/12 py-6 gap-x-2 mx-auto gap-y-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3"}>
                {currentLogos.map(
                    (logosDisplay) => (
                        <div className={"container mx-auto p-2.5"} key={logosDisplay._id}>
                            <div className={"grid grid-cols-1 gap-1"}>

                                <div className="card bg-card hover:bg-card-hover hover:shadow-2xl hover:cursor-pointer hover:border-card auto-rows-auto rounded-4xl border-4 p-4">
                                    <a href={`/logos/${logosDisplay._id}`}>

                                        <div>

                                            <div className={"card-title flex justify-center  py-2 sm:text-small md:text-xl text-black mb-6"}> {logosDisplay.logo_name}</div>

                                            <p className={"text-white"}>{logosDisplay.logo_invoice_number}</p>
                                            <p className={"text-white"}>{logosDisplay.logo_location}</p>

                                        </div>

                                        <div className={"flex justify-end py-2"}>

                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                 fill="#fff" className="w-6 h-6 blackbg">
                                                <path
                                                    d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z"/>
                                                <path
                                                    d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z"/>
                                            </svg>


                                        </div>

                                    </a>

                                </div>

                            </div>

                        </div>

                    ))}

            </div>

        </div>
    );
}

export default LogoList;