import { initializeApp } from "firebase/app";

// For asuthentication purposes we import the following
import {getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAbOjljP5yWiEbDK6WygaefqzxKbNLgq0c",
    authDomain: "embroidery-frontend-e2111.firebaseapp.com",
    projectId: "embroidery-frontend-e2111",
    storageBucket: "embroidery-frontend-e2111.appspot.com",
    messagingSenderId: "934935265660",
    appId: "1:934935265660:web:f1232f9e33023be83f64f9"

};

const app = initializeApp(firebaseConfig);

// We export it because we want to use it on other files
export const auth = getAuth(app);

export default app;


